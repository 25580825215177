<style lang="less" scoped>
#project-url {
  .tab-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .tab-top-line {
    width: 100%;
    height: 2px;
    background-color: #F7F8FA;
    margin-top: 12px;
  }
  .url-info {
    margin: 30px 40px 60px 40px;
    ::v-deep .ant-form-item {
      margin-bottom: 15px ;
      height: 46px;
    }
    ::v-deep .ant-form-item-label {
      width: 100px ;
    }
    ::v-deep .ant-form-item-label,
    label {
      color: #666666;
      font-size: 16px ;
    }
    ::v-deep .ant-form-item-label > label:after {
      content: "" ;
    }
    ::v-deep .ant-form-item-control-wrapper {
      display: inline-block ;
      margin-left: 10px ;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-calendar-picker,
    ::v-deep .ant-calendar-picker-input {
      height: 46px;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-cascader-picker,
    ::v-deep .ant-cascader-input {
      height: 46px;
      width: 400px;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-children, input {
      height: 46px;
      line-height: 46px;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-select-enabled,
    ::v-deep .ant-select-selection,
    ::v-deep .ant-select-selection__rendered {
      height: 46px;
      line-height: 46px;
    }
    .flex-vw {
      display: flex;
      ::v-deep .ant-form-item-control-wrapper,
      ::v-deep .ant-form-item-control,
      ::v-deep .ant-form-item-children,
      ::v-deep .ant-cascader-picker,
      ::v-deep .ant-cascader-input {
        width: 400px;
      } 
      .cus-left {
        margin-left: 80px;
      }
    }
    .tips-div {
      margin-left: 30px;
      margin-top: 30px;
      display: flex;
      .tips-title {
        color: rgba(0, 0, 0, 0.85);
        font-size: 15px;
      }
      .tips-list {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        display: inline-block;
        margin-left: 20px;
        width: 65%;
      }
    }
  }
  .save-button {
    width: 160px;
    height: 48px;
    margin-left: 30px;
    border-radius: 4px;
    background-color: #447df5;
    color: white;
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
  }
}
</style>

<template>
  <div class="project-url" id="project-url">
    <div class="tab-top">
      <table-title :cusTitle="title"/>
    </div>
    <div class="tab-top-line"></div>
    <div class="url-info">
      <template>
        <a-form-model ref="infoForm" :model="form">
          <div class="flex-vw" v-show="urlType==1" style="width: 100%;">
            <a-form-model-item label="单链接" prop="singleUrl" ref="singleUrl" class="cus-left" style="width: 600px;">
              <a-input v-model="form.singleUrl" placeholder="请输入单链接" style="width: 500px;"/>
            </a-form-model-item>
            <a-button type="save" class="save-button" @click="saveSingleUrl" :loading="singleloading">保存</a-button>
          </div>
          <a-form-model-item label="" prop="more_url" ref="more_url" class="cus-left" v-show="urlType==2 && total<=0">
            <a-upload
              name="uploadfile"
              accept="file"
              method="post"
              :action="updateUrl"
              @change="uploadhandleChange"
            >
              <a-button> <a-icon type="upload" />上传多链接文件</a-button>
            </a-upload>
          </a-form-model-item>
        </a-form-model>
      </template>
      <a-button type="primary" @click="deleAllUrl" v-show="urlType==2 && total>0"> <a-icon type="delete" />删除全部</a-button>
      <a-table
        v-show="urlType==2"
        :columns="columns"
        :loading="loading"
        :dataSource="data"
        :pagination="{
          current, 
          total,
          pageSize: size, 
        }"
        @change="tabChange"
        style="margin-top: 13px"
      >
        <div slot-scope="record" slot="action">
          <project-url-set :single_url="record.customer_url" :link_id="record.link_id + ''" @updatelist="initData" />
          <a-divider type="vertical"/>
          <table-delete @comfirm="comfirmDelete(record.link_id)"></table-delete>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import { setSingleUrl, getUrllist, updateUrl, deleUrl, batchdelUrl } from "@/api/project"
const columns = [
  {
    title: "链接ID",
    dataIndex: "link_id",
  },
  {
    title: "链接名称",
    dataIndex: "customer_url",
  },
  {
    title: "qquid",
    dataIndex: "qquid",
  },
  {
    title: "结果",
    dataIndex: "result_text",
  },
  {
    title: "是否分配",
    dataIndex: "allocate_flag_text",
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  }
];

export default {
  name: "ProjectUrl",
  components: {
    "project-url-set": resolve =>
      require(["@/components/model/ProjectUrlSet.vue"], resolve),
    "table-title": resolve =>
      require(["@/components/table/TableTitle.vue"], resolve),
    "table-delete": resolve =>
      require(["@/components/table/TableDelete.vue"], resolve)
  },
  data() {
    return {
      title: '',
      updateUrl: "",
      projectId: undefined,
      urlType: undefined,
      singleloading: false,
      loading: false,
      data: [],
      columns,
      total: 10,
      size: 10,
      current: 1,
      form: {
        singleUrl: undefined,
        singleUrlId: undefined,
        notice_content: undefined,
      },
    };
  },
  created() {
    this.title = this.$route.query.name + '链接配置';
    this.projectId = this.$route.query.projectId;
    this.urlType = this.$route.query.urlType;
    this.updateUrl = '/api/backstage/project/multiurl?project_id=' + this.projectId;
    this.initData();
  },
  methods: {
    initData() {
      this.fetchUrllist();
    },
    async fetchUrllist() {
      const that = this;
      this.loading = true;
      try {
        let res = await getUrllist({
          page_size: this.size,
          page: this.current,
          project_id: this.projectId - 0,
        });
        if (!res) return;
        this.loading = false;
        if (this.urlType == 1) {
          this.form.singleUrl = res.url_list.link_info;
          this.form.singleUrlId = res.url_list.link_id;
          return
        }
        this.total = res.total;
        this.data = res.url_list.map((item, index) => {
          // N 0未返回1甄别2配额满3成功
          switch (item.result) {
            case 0:
              item.result_text = "未返回";
              break;
            case 1:
              item.result_text = "甄别";
              break;
            case 2:
              item.result_text = "配额满";
              break;
            case 3:
              item.result_text = "成功";
              break;
            default:
              item.result_text = "";
              break;
          }
          switch (item.allocate_flag) {
            case 2:
              item.allocate_flag_text = "否";
              break;
            case 1:
              item.allocate_flag_text = "是";
              break;
            default:
              item.result_text = "";
              break;
          }
          item["key"] = item.link_id;
          return item;
        });
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    tabChange(pagination, filters, sorter, { currentDataSource }) {
      let { current, pageSize } = pagination;
      this.current = current;
      this.size = pageSize;
      this.fetchUrllist();
    },
    /// 上传文件
    uploadhandleChange(info) {
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} 上传完成`);
        this.fetchUrllist()
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`);
      }
    },
    /// 删除全部
    async deleAllUrl() {
      this.loading = true;
      const that = this;
      try {
        await batchdelUrl({
          project_id: this.projectId
        });  
        this.$message.success("删除成功");
        this.loading = false;
        this.fetchUrllist();
      } catch (error) {
        this.loading = false;
        console.log(error)
      }
    },
    /// 删除
    comfirmDelete(id) {
      this.deleUrl(id);
    },
    async deleUrl(id) {
      this.loading = true;
      const that = this;
      try {
        await deleUrl({
          link_id: id
        });  
        this.$message.success("删除成功");
        this.loading = false;
        this.fetchUrllist();
      } catch (error) {
        this.loading = false;
        console.log(error)
      }
    },
    /// 设置单链接
    saveSingleUrl() {
      if (this.form.singleUrlId != undefined) {
        this.configSingleUrl(true);
      }else {
        this.configSingleUrl(false);
      }
    },
    /// 设置单链接
    async configSingleUrl(isUpdate) {
      this.singleloading = true;
      const that = this;
      try {
        if (isUpdate) {
          await updateUrl({
            customer_url: this.form.singleUrl,
            link_id: this.form.singleUrlId,
          });
          this.$message.success("更新成功");
        }else {
          await setSingleUrl({
            project_id: this.projectId,
            url: this.form.singleUrl
          });
          this.$message.success("设置成功");
        }
        this.singleloading = false;
        setTimeout(() => {
          that.$router.go(-1);
        }, 500);
      } catch (error) {
        this.singleloading = false;
        console.log(error)
      }
    }
  }
};
</script>