import https from '../utils/https'

// 获取项目列表
export function fetchProjectlist(params) {
  return https.fetchPost('/api/backstage/project/list', params)
}

// 获取项目详情
export function fetchProjectInfo(params) {
  return https.fetchGet('/api/backstage/project/info', params)
}

// 创建项目
export function createProject(params) {
  return https.fetchPost('/api/backstage/project/create', params)
}

// 修改项目
export function updateProject(params) {
  return https.fetchPost('/api/backstage/project/update', params)
}

// 修改项目状态
export function updateProjectStatus(params) {
  return https.fetchPost('/api/backstage/project/modifystatus', params)
}

// 设置单链接链接
export function setSingleUrl(params) {
  return https.fetchGet('/api/backstage/project/singleurl', params)
}

// 设置多连接
export function setmultiurlUrl(params) {
  return https.fetchGet('/api/backstage/project/multiurl', params)
}

// 获取url列表
export function getUrllist(params) {
  return https.fetchPost('/api/backstage/project/urllist', params)
}

// 更新url列表
export function updateUrl(params) {
  return https.fetchPost('/api/backstage/project/updateurl', params)
}

// 删除url
export function deleUrl(params) {
  return https.fetchGet('/api/backstage/project/delurl', params)
}

// 批量删除url
export function batchdelUrl(params) {
  return https.fetchGet('/api/backstage/project/batchdelurl', params)
}

// 获取项目统计
export function getStatistic(params) {
  return https.fetchGet('/api/backstage/project/statistic', params)
}

// 更新项目筛选项
export function updatefilter(params) {
  return https.fetchPost('/api/backstage/project/updatefilter', params)
}